<template>
  <div class="px-2 my-4 border-2 rounded-md mx-2">
    <button class="w-full" @click="rotate">
      <div class="flex justify-between items-center my-4">
        <div class="">
          <div class="scrolling-touch overflow-x-auto">
            <h4 class="w-60 text-left">{{ clientData.name }}</h4>
          </div>
          <div class="flex justify-start scrolling-touch overflow-x-auto mt-3">
            <h4 class="w-60 text-left">{{ clientData.email }}</h4>
          </div>
        </div>
        <img
          :class="classes"
          class="w-5 h-5"
          src="../assets/iconos/arrow.svg"
          alt="arrow"
        />
      </div></button
    ><transition name="expand">
      <div v-if="showCard">
        <div class="my-3 border-t-2"></div>
        <div class="">
          <form action="" method="POST" @submit.prevent="updateClient">
            <div>
              <h4>Nombre Cliente:</h4>
              <input
                class="text-base border-2 pl-2 w-64 rounded-md"
                :class="
                  clientData.name != '' ? 'border-green-300' : 'border-red-500'
                "
                type="text"
                name=""
                id=""
                placeholder="Nombre cliente..."
                v-model="clientData.name"
              />
              <h4 class="mt-2">Email Cliente:</h4>
              <input
                class="text-base border-2 pl-2 w-64 rounded-md"
                :class="
                  clientData.email != '' ? 'border-green-300' : 'border-red-500'
                "
                type="email"
                name=""
                id=""
                placeholder="Email cliente..."
                v-model="clientData.email"
              />
            </div>
            <div class="flex justify-between mb-6 mt-8">
              <button
                class="border bg-red-600 hover:bg-red-800 text-white w-32 rounded-2xl shadow-lg"
                @click="deleteClient"
                type="button"
              >
                Borrar
              </button>
              <button
                type="submit"
                class="border bg-green-500 hover:bg-green-600 text-white w-32 rounded-2xl shadow-lg"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    clienteDataProp: Object,
  },
  data() {
    return {
      showCard: false,
      classes: "",
      clientData: {
        name: "",
        email: "",
      },
    };
  },
  mounted() {
    this.clientData.name = this.clienteDataProp.name;
    this.clientData.email = this.clienteDataProp.email;
  },
  methods: {
    async updateClient() {
      if (this.clientData.name != "" && this.clientData.email != "") {
        if (confirm("¿Presione Aceptar para editar los datos del cliente?")) {
          this.$emit("dataClientValidation", {
            email: this.clientData.email,
            client_id: this.clienteDataProp.id,
          });
          if (this.$store.state.clientDataValidated) {
            await this.$apollo
              .mutate({
                mutation: require("@/graphql/mutations/updateClientProveedor.gql"),
                variables: {
                  id: this.$store.state.user.id,
                  client_id: parseInt(this.clienteDataProp.id),
                  data: {
                    name: this.clientData.name,
                    email: this.clientData.email,
                  },
                },
              })
              .then(() => {
                this.$store.state.isLoading = false;
                alert("¡Datos actualizados!");
              })
              .catch((error) => {
                this.$store.state.isLoading = false;
                console.error(error);
                alert("Error al actualizar datos, vuelva a intentarlo.");
              });
          } else {
            alert("El email ya existe en la base de datos.");
          }
        }
      } else {
        alert("Los campos email y nombre no pueden estar vacios.");
      }
    },
    async deleteClient() {
      if (
        confirm(
          "¿Desea BORRAR el registro " +
            this.clienteDataProp.name +
            " de manera permanente?. Este cambio no será reversible."
        )
      ) {
        await this.$apollo
          .mutate({
            mutation: require("@/graphql/mutations/deleteClientProveedor.gql"),
            variables: {
              id: parseInt(this.$store.state.user.id),
              client_id: parseInt(this.clienteDataProp.id),
            },
          })
          .then(() => {
            this.$store.state.isLoading = false;
            alert("¡Data borrada con exito!");
            this.$emit("reloadData");
          })
          .catch((error) => {
            this.$store.state.isLoading = false;
            console.error(error);
            alert("Error al borrar registro, vuelva a intentarlo.");
          });
      }
    },
    rotate() {
      if (!this.showCard) {
        this.classes = "rotate-arrow-enter";
      } else {
        this.classes = "rotate-arrow-leave-to";
      }
      this.showCard = !this.showCard;
    },
  },
};
</script>

<style scoped>
.rotate-arrow-enter, .rotate-arrow-leave-to
/* .rotate-arrow-leave-active below version 2.1.8 */ {
  transform: rotate(180deg);
  transition: all 0.3s;
}
.rotate-arrow-leave-to
/* .rotate-arrow-leave-active below version 2.1.8 */ {
  transform: rotate(0deg);
  transition: all 0.3s;
}

.expand-enter-active {
  transition: max-height 0.25s ease;
  max-height: 24rem;
}
.expand-leave-active {
  transition: max-height 0.1s ease;
  max-height: 24rem;
}
.expand-enter,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
