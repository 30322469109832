<template>
  <div>
    <div class="flex justify-center mt-6">
      <h4 class="text-xl font-semi-bold">Datos Cliente</h4>
    </div>
    <div class="flex justify-center px-3">
      <transition name="expand">
        <input
          v-if="$store.state.openSearch"
          class="w-full border-green-300 border-2 text-xl rounded-md mt-4 pl-2"
          type="text"
          placeholder="Buscar nombre cliente..."
          v-model="searchInput"
        />
      </transition>
    </div>
    <transition name="fade">
      <div v-if="!$store.state.openSearch" class="my-8 border-b-2">
        <form
          class="mx-2"
          action=""
          method="POST"
          @submit.prevent="createClient"
        >
          <div class="flex justify-center">
            <input
              class="text-xl border-2 pl-2 w-72 border-green-300 rounded-md"
              type="text"
              name=""
              id=""
              placeholder="Nombre Cliente..."
              v-model="newClient.name"
            />
          </div>
          <div class="flex justify-center mt-8">
            <input
              class="text-xl border-2 pl-2 w-72 border-green-300 rounded-md"
              type="email"
              name=""
              id=""
              placeholder="Email Cliente..."
              v-model="newClient.email"
            />
          </div>
          <div class="flex justify-center my-8">
            <button
              class="border sticky border-green-400 bg-green-500 hover:bg-green-600 hover:border-green-500 text-xl text-white w-40 rounded-2xl shadow-lg align-middle"
            >
              Agregar
            </button>
          </div>
        </form>
      </div>
    </transition>
    <div class="my-8">
      <span v-for="(cliente, k) in filteredList" :key="k">
        <transition name="fade">
          <emailCard
            :clienteDataProp="cliente"
            @reloadData="listaCorreoProveedor"
            @dataClientValidation="dataClientValidationCard"
            :key="keyComponent"
          />
        </transition>
      </span>
    </div>
  </div>
</template>

<script>
import emailCard from "@/components/EmailCard";
import getIdMixin from "@/mixins/getIdMixin";
import listaEmailsProveedorMixin from "@/mixins/listaEmailsProveedorMixin";

export default {
  components: {
    emailCard,
  },
  data() {
    return {
      dataEmail: [],
      searchInput: "",
      keyComponent: 0,
      newClient: {
        name: "",
        email: "",
      },
    };
  },
  mixins: [getIdMixin, listaEmailsProveedorMixin],
  methods: {
    dataClientValidationParent() {
      this.$store.state.clientDataValidated = true;
      this.dataEmail.map((x) => {
        if (x.email == this.newClient.email) {
          this.$store.state.clientDataValidated = false;
        }
      });
    },
    dataClientValidationCard(event) {
      this.$store.state.clientDataValidated = true;
      this.dataEmail.map((x) => {
        if (x.email == event.email && x.id != event.client_id) {
          this.$store.state.clientDataValidated = false;
        }
      });
    },
    async createClient() {
      if (this.newClient.name != "" && this.newClient.email != "") {
        this.dataClientValidationParent();
        if (this.$store.state.clientDataValidated) {
          this.$store.state.isLoading = true;
          await this.$apollo
            .mutate({
              mutation: require("@/graphql/mutations/createClientProveedor.gql"),
              variables: {
                id: parseInt(this.$store.state.user.id),
                data: {
                  local_id: parseInt(this.$store.state.user.id),
                  name: this.newClient.name,
                  email: this.newClient.email,
                },
              },
            })
            .then(() => {
              this.$store.state.isLoading = false;
              this.newClient.name = "";
              this.newClient.email = "";
              alert("Data guardada con exito");
              this.listaCorreoProveedor();
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              console.error(error);
              alert("Error al crear los datos, vuelva a intentarlo.");
            });
        } else {
          alert("El email ya existe en la base de datos.");
        }
      } else {
        alert("Los campos email y nombre no pueden estar vacios.");
      }
    },
  },
  computed: {
    filteredList() {
      if (this.$store.state.openSearch) {
        return this.dataEmail.filter((cliente) => {
          return cliente.name
            .toLowerCase()
            .includes(this.searchInput.toLowerCase());
        });
      } else {
        return this.dataEmail.filter((cliente) => {
          return cliente.name;
        });
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
